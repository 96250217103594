@font-face {
  font-family: "Great Vibes";
  src: url("../assests/GreatVibes-Regular.ttf") format("truetype");
}

body {
    font: 14px "Century Gothic", Futura, sans-serif;
    margin-top: 0px;
    margin-bottom: 0rem;
    margin-left: 0px;
    margin-right: 0px;
    color: #c5929b;
    background-color: #B4A9AE;
  }

  .title {
    justify-content: center;
    display: flex;
  }

  .flex-container {
    padding: 3rem 0rem;
    display: flex;
    height: fit-content;
    width: 100%;
    flex-wrap: nowrap;
    background-color: #f7f7f7;
    margin-bottom: 6rem;
    justify-content: center;
    border: 0px solid black;
  }
  
  .social-media-ii {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    padding-inline: 10rem;
  }

  .social-media {
    width: 10rem;
    justify-content: right;
    text-align: right;
    border: 0px solid black;
    padding-right: 0rem;
  }

  .icons {
    padding: 1rem 0rem 1rem;
    font-size: 2rem;
  }

  .icons a {
    color: #F3BFC7;
    font-size: 3rem;
  }

  .icons-ii {
    color: #F3BFC7;
    padding-inline: 2rem;
    padding-bottom: 2rem;
    font-size: 3rem;
  }

  .icons-ii a {
    color: #F3BFC7;
    font-size: 3rem;
  }

  .picture-gallery-loop {
    width: fit-content;
    justify-content: center;
    text-align: center;
    padding: 0rem 2rem
  }

  .picture-gallery-loop img {
    border-radius: 10px;
    height: auto;
    width: fit-content;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .picture-gallery-loop img:hover {
    box-shadow: 0 4px 8px 0 #F3BFC7, 0 6px 20px 0 #F3BFC7;
  }

  img.rounded-corners {
    border-radius: 20px;
  }

  .empty {
    width: 10rem;
    border: 0px solid black;
  }

  .services {
    background-color: #f7f7f7;
    border: none;
    width: 100%;
    padding-bottom: 5rem;
  }

  .service-name {
    display: inline-block;
    text-align: left;
    width: 90%;
  }
  
  .arrow {
    display: inline-block;
    text-align: right;
    width: 10%;
  }

  .service-item {
    color: #B4A9AE;
    border: none;
    width: 100%;
    padding: 0;
    display: grid;
    justify-self: center;
    justify-content: center;
  }

  .service-button {
    background-color: transparent;
    color: #f3bfc7;
    width: 33rem;
    height: 70px;
    padding: 0;
    border: none;
    font-size: 22px;
    font-weight: 600;
  }

  .service-button:hover {
    cursor: pointer;
  }

  .line {
    background-color: rgba(180, 169, 174, 0.4);
    width: 33rem;
    height: 2px;
    padding: 0;
    border: none;
  }

  .service-description {
    display: flex;
    width: 33rem;
    vertical-align: middle;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 20px;
    font-size: 18px;
    line-height: 30px;
  }

  .products {
    display: flex;
    flex-direction: row;
    height: fit-content;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .right_scroll_arrows {
    margin-left: 1rem;
    width: 10%;
    text-align: left;
  }

  .left_scroll_arrows {
    margin-right: 1rem;
    width: 10%;
    text-align: right;
  }

  .product {
    display: flex;
    width: fit-content;
    height: fit-content;
    max-width: 500px;
    text-align: center;
  }

  .product img {
    height: auto;
    width: 100%;
    display: flex;
  }

  .product{
    /* animation-duration: 1s; */
    z-index: 20;
    /* transition: opacity 0.25s;
    opacity: 1.0; */
  }

  .product-hidden {
    opacity: 0;
    transition: opacity 250ms ease-in-out;
  }

  .arrow-symbols {
    color: #F3BFC7;
    font-size: 75px;
  }

  .arrow-symbols:hover {
    cursor: pointer;
  }

  h1, h2, h3 {
    padding-top: 40px;
    text-align: center;
    font-family: "Great Vibes", serif;
    font-size: 4rem;
    color: pink;
}

.navbar {
  height: fit-content;  
  display: flex;
  flex-direction: column;
  background-color: #f7f7f7;
  border: 0px solid #F3BFC7;
  color: #F3BFC7;
  align-items: center;
  font-size: 20px;
  padding: 1rem 0rem;
  width: 100%;
  max-width: 100%;
}

.navbar-title {
  width: 100%;
  border: 0px solid black;
  padding-left: 0rem;
  text-align: center;
}

.navbar-title h3 {
  padding-top: 0rem;
  text-align: center;
  margin-right: 0rem;
  text-shadow: 2px 2px 4px #000000;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.navbar-links {
  width: 50%;
  display: flex; 
  flex-direction: horizontal;
  border: 0px solid black;
  justify-content: center;
  padding-right: 0rem;
  font-weight: 600;
  

}

.navlink-active{
  padding: 0rem 3px 7px;
  margin-inline: 1rem;
  border-bottom: 3px solid #B4A9AE;
}

.navlink-pending{
  padding: 0rem 3px 7px;
  margin-inline: 1rem;
  border-bottom: 3px solid #f7f7f7;
  transition: .25s;
}

.navlink-pending:hover{
  cursor: pointer;
  border-bottom: 3px solid #B4A9AE;
}

.page-container{
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  margin-bottom: 0rem;
}

.fa-globe:hover{
  filter: drop-shadow(0px 0px 1px #B4A9AE);
}

.flex-container-ii{
  padding: 5rem 0rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-wrap: nowrap;
  background-color: #f7f7f7;
  margin-bottom: 100px;
  justify-items: center;
}

.showcase-container{
  width: 100%;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  border: 0px solid black;
  padding-top: 0rem;
}

.showcase-item{
  justify-self: center;
  border: 0px solid black;
  margin-left: auto;
  margin-right: auto;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.showcase-img {
  display: block;
  padding: 2rem;

}

.showcase-img img {
  height: 30rem;
  width: auto;
  border-radius: 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.showcase-txt {
  margin-left: 3rem;
}


.showcase-txt h3 {
  text-align: left;
  margin-left: 4rem;
  margin-right: 1rem;
  text-shadow: 2px 2px 4px #000000;
}

.services h1{
  font-size: 7em;
  text-shadow: 2px 2px 4px #000000;
  padding-top: 4rem;
  margin-bottom: 0rem;
  padding-bottom: 1rem;
}

.services-container {
  width: 100%;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: horizontal;
  border: 0px solid black;
  justify-content: space-between;
  padding-top: 0rem;
}

.services-intro-txt {
  width: 40%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 22px;
  padding-bottom: 3rem;
  font-weight: 500;
  color: #B4A9AE;
  line-height: 32px;
}

.service-intro {
  width: 40%;
  font-size: 22px;
  display: block;
  max-height: initial;
  text-align: justify;
  padding: 2rem;
  border-radius: 2%;
  margin-left: 2rem;
  color: #B4A9AE;
}

.service-intro-img {
  width: 100%;
  height: auto;
}

.service-intro-img img{
  width: inherit;
  height: auto;
}

.service-item-container {
  margin-right: 2rem;
  border: 0px solid black;
}

.footer{
  padding: 3rem 2rem 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: fit-content;
}

.footer-logo{
  text-align: center;
  width: 33%;
}

.footer h3{
  padding: 0;
  margin: 0;
  color: #f7f7f7;
  text-shadow: 2px 2px 4px #000000;
  width: 100%;
}

.footer p{
  color: #f7f7f7;
  font-size: 16px;
}

.footer-vertical-line{
  height:inherit;
  width: 0px;
  background-color: #f7f7f7;
}

.footer-social-media{
  width: 33%;
  border-left: 1px solid #f7f7f7;
  border-right: 1px solid #f7f7f7;
}

.footer-social-media p{
  text-align: center;
}

.footer-icons{
  display: flex;
  flex-direction: row;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  padding: 0rem 0rem;
}

.footer .navbar-button{
  text-align: center;
  border: none;
}

.footer .navlink-active{
  border: none;
}

.footer .navlink-pending{
  border: none;
}

.footer-icons .icons-ii{
  padding: 0rem;
  padding-inline: 1rem;
}

.footer-icons .icons-ii a{
  font-size: 3rem;
  padding: 0rem;
}

.footer .navbar-links{
  display: flex;
  flex-direction: column;
  color: #f7f7f7;
  font-size: 16px;
  font-weight: 500;
  padding: 0rem;
  margin: 0rem;
  width: 33%;
  justify-content: space-evenly;
}

.fade-in {
  opacity: 0;
  animation: fadeIn 250ms ease-in forwards;
}

.fade-out {
  opacity: 1;
  animation: fadeOut 250ms ease-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}


@media (width <=600px) {
  h3 {
    font-size: 3rem;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-top: 0rem;
  }

  .navbar{
    padding-top: 0rem;
    padding-bottom: 2rem;
  }

  .navbar-button{
    padding-inline: 0rem;
  }

  .navlink-pending{
    margin-inline: 0rem;
  }

  .navlink-active{
    margin-inline: 0rem;
  }
  
  .navbar-links {
    width: 95%;
    font-size: 16px;
    justify-content: space-between;
  }
  
  .flex-container{
    flex-direction: column;
    padding-top: 0rem;
    margin-bottom: 3rem;
  }

  .social-media {
    width: 100%;
    justify-content: space-evenly;
    display: flex;
    flex-direction: row;
  }

  .icons {
    padding: 0rem 0rem 1rem;
  }

  .icons a {
    font-size: 2rem;
  }

  .conectate {
    display: none;
  }

  .picture-gallery-loop {
    width: 100%;
    justify-content: center;
    padding: 0rem 0rem;
    border: 0px solid black;
  }

  .picture-gallery-loop img {
    border-radius: 10px;
    height: auto;
    width: 85%;
  }

  .empty {
    display: none;
  }

  .flex-container-ii{
    padding: 1rem 0rem;
    margin-bottom: 0rem;
  }
  
  .showcase-container{
    width: 100%;
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    border: 0px solid black;
    padding-top: 0rem;
  }
  
  .showcase-item{
    flex-direction: column;
    justify-content: center;
    border: 0px solid black;
    width: 100%;
    margin-bottom: 3rem;
  }

  #showcase-item-1{
    flex-direction: column-reverse;
    margin-top: 1rem;
  }

  #showcase-item-3{
    flex-direction: column-reverse;
    margin-bottom: 1rem;
  }
  
  .showcase-img img {
    width: 100%;
    height: auto;
  }
  
  .showcase-txt {
    margin-left: 0rem;
  }
  
  
  .showcase-txt h3 {
    text-align: center;
    margin: 1rem 0rem 0rem; 
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .services h1{
    font-size: 4rem;
    padding-right: 1rem;
    padding-left: 1rem;
    padding-top: 2rem;
  }
  
  .services-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 0rem;
  }
  
  .services-intro-txt {
    width: 80%;
    padding-bottom: 0rem;
    font-size: 20px;
  }
  
  .service-intro {
    width: 100%;
    margin: 1rem 0rem;
    padding: 0rem
  }
  
  .service-intro-img {
    width: 50%;
    height: auto;
    margin: 1rem auto;
  }
  
  .service-intro-img img{
    width: 100%;
    height: auto;
  }
  
  .service-item-container {
    margin-right: 0rem;
  }

  .service-item-block{
    width: 90%;
  }

  .service-item {
    display: flex;
    flex-direction: row;
    padding: 0rem;
  }

  .service-button {
    width: 100%;
    font-size: 18px;
    justify-content: space-between;
  }

  .service-name {
    display: inline-block;
    font-size: 20px;
  }
  
  .arrow {
    display: inline-block;
  }

  .line {
    width: 100%;
  }

  .service-description {
    display: flex;
    width: 100%;
    padding-bottom: 2rem;
    font-size: 16px;
  }

  .products {
    display: flex;
    flex-direction: row;
    width: 95%;
    height: fit-content;
    justify-content: center;
    align-items: center;
    margin-right: auto;
    margin-left: auto;
  }

  .right_scroll_arrows {
    width: 10%;
    text-align: left;  
  }

  .left_scroll_arrows {
    width: 10%;
    text-align: right;
  }

  .arrow-symbols {
    font-size: 4rem;
  }

  .product {
    width: 80%;
    height: auto;
    text-align: center;
    padding: 1rem 1rem 0rem;
  }

  .product img {
    height: auto;
    width: 100%;
    display: inline-block;
  }

  .social-media-ii {
    margin-top: 3rem;
    justify-content: space-evenly;
    padding-inline: 0rem;
  }

  .icons-ii {
    padding-inline: 0rem;
  }

  .footer{
    flex-direction: column-reverse;
    padding-top: 1rem;
  }

  .footer-logo{
    width: fit-content;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 1rem;
  }

  .footer-logo h3{
    margin-top: 1rem;
  }

  .footer-social-media{
    width: 100%;
    border-left: 0px;
    border-right: 0px;
    border-top: 1px solid #f7f7f7;
    border-bottom: 1px solid #f7f7f7;
    margin: 1rem 0rem;
    padding: 1rem 0rem;
  }

  .footer-icons{
    justify-content: space-evenly;
  }

  .footer-icons .icons-ii{
    padding-inline: 1rem;
    padding-bottom: 1rem;
  }

  .footer .navbar-links{
    flex-direction: row;
    width: 100%;
    margin: 1rem 0rem;
  }

}
