.container {
    width: 100%;
    display: flex;
    justify-content: center;
}

form {
    background: white;
    display: flex;
    flex-direction: column;
    padding: 1rem 4vw 5rem;
    width: 90%;
    max-width: 600px;
    border-radius: 10px;
}

form h3 {
    font: 30px "Century Gothic", Futura, sans-serif;
    color: #F3BFC7;
    font-weight: 600;
    margin-bottom: 2rem;
    margin-top: 1rem;
    text-align: left;
}

form input, form textarea {
    border: 0;
    margin: 10px 0;
    padding: 20px;
    outline: none;
    background: #f5f5f5;
    font-size: 16px;
}

form button {
    background: #F3BFC7;
    color: white;
    border-radius: 30px;
    width: 150px;
    margin: 20px auto 0;
    font-size: 18px;
    border: 0;
    outline: none;
    padding: 15px;
    cursor: pointer;
    align-items: center;
    transition: .25s;
}

form button:hover {
    background-color: #e4b6bd;
    opacity: 1;
    animation-timing-function: ease;
}

@media (width <= 600px) {
    .container {
        width: 90%;
        margin: 0rem auto;
    }

    form {
        padding: 2rem 2rem 3rem;
    }

    form h3 {
        text-align: left;
        font-size: 26px;
        padding-left: 0rem;
    }


    form button {
        background: #F3BFC7;
        color: white;
        border-radius: 30px;
        width: 150px;
        margin: 20px auto 0;
        font-size: 18px;
        border: 0;
        outline: none;
        padding: 15px;
        cursor: pointer;
        align-items: center;
        transition: .25s;
    }

}

